import React from "react";
import { graphql } from "gatsby";

import SEO from "../components/seo";
import CarouselLayout from "../components/carousel-layout";

export const query = graphql`
  query ContextPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      _rawContextPageContent(resolveReferences: { maxDepth: 5 })
    }
  }
`;

const ContextPage = ({ data }) => {
  return (
    <>
      <SEO title="Context" />
      <CarouselLayout
        title={<h1>Context</h1>}
        blocks={data?.site?._rawContextPageContent}
      />
    </>
  );
};

export default ContextPage;
